/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Config from '../../Config'
import { GetAllBlogsSortBy } from '../../api/FetchBlog'
import { Link } from 'react-router-dom'
import { AddCustomerEamil } from '../../api/FetchCustomer'
import { getSocialMedia } from '../../api/FetchSocialMedia'

export function Footer() {
    const [Posts, setPosts] = useState([])
    const [SocialMedia, setSocialMedia] = useState([])

    useEffect(() => {
        GetAllBlogsSortBy({
            field: 'createdAt',
            sortBy: 'desc',
            size: 3
          }, (data) => {
            setPosts(data)
        })

        getSocialMedia(data => {
            if(data) setSocialMedia(data)
        })
    }, [])

    function handleOnClick(e) {
        e.preventDefault()
        let email = document.getElementById('footer_email')

        AddCustomerEamil(email.value, (status) => {
            if (status === 'success') {
                email.value = ''
            }
        })
    }

    function formatBlogPath(title) {
        return title.replace(/\s+/g, '-').toLowerCase();
    }

    return (
        <footer className="footer widget-footer clearfix">
            <div className="first-footer ttm-bgcolor-skincolor ttm-bg ttm-bgimage-yes bg-img1">
                <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
                <div className="container-xl">

                    <div className="row align-items-md-center">

                        <div className="col-lg-4 col-md-4 col-sm-12 order-md-2">
                            <div className="text-left">
                                {/*  featured-icon-box */}
                                <div style={{ display: 'none' }} className="featured-icon-box left-icon icon-align-top">
                                    <div className="featured-icon">{/*  featured-icon */}
                                        <div className="ttm-icon ttm-textcolor-white ttm-icon_element-size-md">
                                            <i className="ti ti-location-pin" />{/*  ttm-icon */}
                                        </div>
                                    </div>
                                    <div className="featured-content">{/*  featured-content */}
                                        <div className="featured-desc">
                                            <p>{Config.address}</p>
                                        </div>
                                    </div>
                                </div>{/*  featured-icon-box END */}
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-6 order-md-1 res-767-mt-20">
                            <div className="footer-logo text-sm-center">
                                <img data-src="images/footer-logo.png" className="img-fluid" alt="footer-logo" src="images/footer-logo.png" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 order-md-3 res-767-mt-20">
                            <div className="text-sm-right">
                                <a href={`mailto:${Config.mail}`} style={{ fontSize: '12px', textTransform: 'lowercase', wordBreak: 'break-all' }} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white"><i className="far fa-envelope" />{Config.mail}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="second-footer ttm-textcolor-white bg-img2">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                            <div className="widget widget_text  clearfix">
                                <h3 className="widget-title">About Our Company</h3>
                                <div className="textwidget widget-text">
                                    We are the best world Information Technology Company. Providing the highest quality services &amp; IT solutions. With many years of experience, we provide our services world wide.
                                </div>
                                <div className="quicklink-box">
                                    {/*  featured-icon-box */}
                                    <div className="featured-icon-box left-icon">
                                        <div className="featured-icon">{/*  featured-icon */}
                                            <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                <span className="flaticon flaticon-clock" />{/*  ttm-icon */}
                                            </div>
                                        </div>
                                        <div className="featured-content">{/*  featured-content */}
                                            <div className="featured-desc">{/*  featured-desc */}
                                                <p>Talk To Our Support</p>
                                            </div>
                                            <div className="featured-title">{/*  featured-title */}
                                                <h5><a href={`tel:${Config.phoneNo2}`}>{Config.phoneNo1}</a></h5>
                                            </div>
                                        </div>
                                    </div>{/*  featured-icon-box END */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                            <div className="widget link-widget clearfix">
                                <h3 className="widget-title">Quick Links</h3>
                                <ul id="menu-footer-services">
                                    <li><Link to={'/services'}>Services</Link></li>
                                    <li><Link to={'/portfolio'}>Portfolio</Link></li>
                                    <li><Link to={'/blogs'}>Blogs</Link></li>
                                    <li><Link to={'/aboutus'}>About</Link></li>
                                    <li><Link to={'/contact'}>Contact</Link></li>
                                    <li><Link to={'/pricing'}>Pricing</Link></li>
                                    <li><Link to={'/getaquote'}>Get a Quote</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area res-991-mb-40 res-767-mb-0">
                            <div className="widget widget_text clearfix">
                                <h3 className="widget-title">Latest News</h3>
                                <ul className="widget-post ttm-recent-post-list">
                                    {
                                        Posts.map((post, index) => {
                                            if (index < 3) {
                                                return (
                                                    <li key={post._id}>
                                                        <Link
                                                            to={{
                                                                pathname: `${process.env.PUBLIC_URL}/blog/${formatBlogPath(post.title)}`,
                                                                state: { blog_id: post._id, blog_data: post }
                                                            }}
                                                        >
                                                            <img className="img-fluid" alt="post-img" src={Config.server + post.image} />
                                                        </Link>
                                                        <Link
                                                            to={{
                                                                pathname: `${process.env.PUBLIC_URL}/blog/${formatBlogPath(post.title)}`,
                                                                state: { blog_id: post._id, blog_data: post }
                                                            }}
                                                        >
                                                            {post.title}
                                                        </Link>
                                                        <span className="post-date"><i className="fa fa-calendar" />{post.date}</span>
                                                    </li>
                                                )
                                            }
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area res-767-mb-40">
                            <div className="widget flicker_widget clearfix">
                                <h3 className="widget-title">News Letter</h3>
                                <div className="textwidget widget-text">
                                    Sign up today for hints, tips and the latest product news
                                    <form id="subscribe-form" className="newsletter-form" method="post" action="#" data-mailchimp="true">
                                        <div className="mailchimp-inputbox clearfix" id="subscribe-content">
                                            <i className="fa fa-envelope" />
                                            <input style={{ paddingRight: '70px' }} id="footer_email" type="email" name="email" placeholder="Email Address.." required />
                                            <input id="footer_submit_email" type="submit" value="" onClick={(e) => handleOnClick(e)} />
                                        </div>
                                        <div id="subscribe-msg" />
                                    </form>
                                    <br />
                                    <br />
                                    <h5>Follow Us On</h5>
                                    <div className="social-icons circle social-hover">
                                        <ul className="list-inline">
                                            <li className="social-facebook"><a rel="noopener noreferrer" href={SocialMedia.facebook} className="tooltip-top rounded-circle" target="_blank" data-tooltip="Facebook"><i className="fab fa-facebook-f" /></a></li>
                                            <li className="social-twitter"><a rel="noopener noreferrer" href={SocialMedia.twitter} className="tooltip-top rounded-circle" target="_blank" data-tooltip="Twitter"><i className="fab fa-twitter" /></a></li>
                                            <li className="social-instagram"><a rel="noopener noreferrer" href={SocialMedia.instagram} className=" tooltip-top rounded-circle" target="_blank" data-tooltip="Instagram"><i className="fab fa-instagram" /></a></li>
                                            <li className="social-linkedin"><a rel="noopener noreferrer" href={SocialMedia.linkedIn} className=" tooltip-top rounded-circle" target="_blank" data-tooltip="LinkedIn"><i className="fab fa-linkedin-in" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-footer-text ttm-bgcolor-darkgrey ttm-textcolor-white">
                <div className="container-xl">
                    <div className="row copyright align-items-center res-767-text-center">
                        <div className="col-md-6">
                            <div>
                                <span>Copyright © 2021&nbsp;<Link to="/">jWare Automation (Pvt) Ltd. </Link></span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="text-md-right res-767-mt-10">
                                <div className="d-lg-inline-flex">
                                    <ul id="menu-footer-menu" className="footer-nav-menu">
                                        <li><Link to="/privacy_policy">Privacy Policy</Link></li>
                                    </ul>
                                    <div className="float-md-right ml-20 res-767-ml-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}