import React, { useEffect, useState } from 'react';
import Config from '../../Config';
import Menu from './menu';
import Mobilemenu from './Mobile_menu';
import { Link } from 'react-router-dom';
import { getSocialMedia } from '../../api/FetchSocialMedia';

export function Header3 () {

    const [SocialMedia, setSocialMedia] = useState([])
    const [LogoImage, setLogoImage] = useState('images/logo.png')

    useEffect(() => {
        let isMounted = true;

        if(isMounted) {
            getSocialMedia(data => {
                if(data) setSocialMedia(data)
            })
            handleOnResize()
            window.addEventListener('resize', handleOnResize)
        }

        return () => {
            window.removeEventListener('resize', handleOnResize)
            isMounted = false
        }
    }, [])

    function handleOnResize() {
        if(window.innerWidth < 1200) {
            setLogoImage('images/footer-logo.png')
        }else{
            setLogoImage('images/logo.png')
        }
    }

    return (
        <header id="masthead" className="header ttm-header-style-03">
            {/* ttm-topbar-wrapper */}
            <div className="ttm-header-wrap">
                <div className="site-header-menu">
                    <div id="navbar" className="ttm-stickable-header clearfix">
                        <div className="container-xl">
                            <div className="d-xl-flex flex-xl-row align-items-xl-center justify-content-xl-between">
                                <div className="site-branding">
                                    <Link className="home-link" to={'/'} title="jWareAutomation" rel="home">
                                        <img id="logo-img" className="img-fluid" alt="logo" src={LogoImage} />
                                    </Link>
                                </div>
                                <div className="site-navigation">
                                    <div className="ttm-topbar-wrapper clearfix">
                                        <div className="container-xl">
                                            <div className="row">
                                                <div className="col-lg-12 text-right">
                                                    <ul className="top-contact">
{/*                                                             <li><i className="fas fa-map-marker-alt" />&nbsp;&nbsp;{Config.address}</li> */}
                                                        <li><i className="far fa-envelope" />&nbsp;&nbsp;<a href={`mailto:${Config.mail}`}>{Config.mail}</a></li>
                                                        <li><i className="far fa-clock" />&nbsp;&nbsp;Office Hour: 08:30 am - 5:30 pm</li>
                                                    </ul>
                                                    <div className="ttm-social-links-wrapper list-inline">
                                                        <ul className="social-icons">
                                                            <li><a rel="noopener noreferrer" target="_blank" href={SocialMedia.facebook} className=" tooltip-bottom" data-tooltip="Facebook"><i className="fab fa-facebook-f" /></a>
                                                            </li>
                                                            <li><a rel="noopener noreferrer" target="_blank" href={SocialMedia.twitter} className=" tooltip-bottom" data-tooltip="Twitter"><i className="fab fa-twitter" /></a>
                                                            </li>
                                                            <li><a rel="noopener noreferrer" target="_blank" href={SocialMedia.instagram} className=" tooltip-bottom" data-tooltip="Instagram"><i className="fab fa-instagram" /></a>
                                                            </li>
                                                            <li><a rel="noopener noreferrer" target="_blank" href={SocialMedia.linkedIn} className=" tooltip-bottom" data-tooltip="LinkedIn"><i className="fab fa-linkedin-in" /></a>
                                                            </li>
                                                        </ul>
                                                    </div>                                                        
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-xl">
                                        <div className="row align-items-center ml-auto">
                                            <div className="ml-auto">
                                                <Menu /> 
                                                <div className="mobilemenu"><Mobilemenu/></div>
                                            </div>
                                            <div className="ttm-right-header ml-auto pl-0">                                        
                                                <ul className="ttm-header-icons">
{/*                                                     <li className="ml-4 ttm-header-search-link ttm-bgcolor-skincolor ttm-textcolor-white">
                                                    <a onClick={this.toggle}> {this.state.show ? <i className="ti-close" /> : <i className="ti-search" />}</a>
                                                    {this.state.show && <div className="ttm-search-overlay">
                                                        <form className="ttm-site-searchform">
                                                            <div className="w-search-form-h">
                                                                <div className="w-search-form-row">
                                                                    <div className="w-search-input">
                                                                        <input type="search" className="field searchform-s" name="s" placeholder="Type Word Then Enter..." />
                                                                        <button type="submit">
                                                                            <i className="ti ti-search" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>}
                                                    </li> */}
                                                    {/* <li className="ttm-header-cart-link ttm-bgcolor-darkgrey ttm-textcolor-white"><a href={process.env.PUBLIC_URL + '/Cart'}><i className="ti-shopping-cart" /><span>0</span></a></li> */}
                                                </ul>                                            
                                            </div>  
                                            <div className="ml-auto right-btn"><button className="ml-4 ttm-btn ttm-btn-size-xs ttm-textcolor-white ttm-bgcolor-skincolor"><a href={process.env.PUBLIC_URL + '/getaquote'}>get a quote</a></button></div>
                                        </div>    
                                    </div>                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
    
}

export default Header3;