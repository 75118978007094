import axios from 'axios'
import Config from '../Config'

export function GetAllBlogsSortBy(data, callback) {
  axios({
    method: 'POST',
    url: Config.server + 'api/getAllBlogsSortBy',
    data: { 
        field: data.field,
        sortBy: data.sortBy,
        last_id: data.last_id,
        size: data.size
     },
  }).then(res => {
    callback(res.data.data)
  }).catch(err => {
    callback([])
    console.log(err)
    if(err.response){
        if(err.response.data.message){
            console.log('error', err.response.data.message)
        }
    }else{
        console.log('error', 'Error')
    }
  })
}

export function GetBlogsPages(data, callback) {
  axios({
    method: 'POST',
    url: Config.server + 'api/getBlogsPages',
    data: data
  }).then(res => {
    callback(res.data.data)
  }).catch(err => {
    callback([])
    console.log(err)
    if(err.response){
        if(err.response.data.message){
            console.log('error', err.response.data.message)
        }
    }else{
        console.log('error', 'Error')
    }
  })
}

export function GetAllBlogsFilterd(data, callback) {
  
    if(data.sortField === undefined){
      data.sortField = 'createdAt'
    }
    if(data.sortOrder === undefined){
      data.sortOrder = 'desc'
    }

    axios({
      method: 'POST',
      url: Config.server + 'api/getBlogFilterd',
      data: { 
          field: data.field,
          value: data.value,
          last_id: data.last_id,
          size: data.size,
          sort: {
            field: data.sortField,
            order: data.sortOrder
          }
      },
    }).then(res => {
      callback(res.data.data)
    }).catch(err => {
      callback([])
      console.log(err)
      if(err.response){
          if(err.response.data.message){
              console.log('error', err.response.data.message)
          }
      }else{
          console.log('error', 'Error')
      }
    })

}

export function GetBlogById(id, callback) {

  axios({
    method: 'GET',
    url: Config.server + 'api/getBlog/'+id,
  }).then(res => {
    callback(res.data.data)

  }).catch(err => {
    callback([])
    console.log(err)

    if(err.response){
        if(err.response.data.message){
            console.log('error', err.response.data.message)
        }
    }else{
        console.log('error', 'Error')
    }
  })

}

export function GetBlog(data, callback) {

  axios({
    method: 'POST',
    url: Config.server + 'api/getBlog',
    data: {
      id: data.id,
      path: data.path
    }
  }).then(res => {
    callback(res.data.data)

  }).catch(err => {
    callback([])
    console.log(err)

    if(err.response){
        if(err.response.data.message){
            console.log('error', err.response.data.message)
        }
    }else{
        console.log('error', 'Error')
    }
  })

}