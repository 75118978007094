import axios from 'axios'
import { notify } from '../components/notify/notify'
import Config from '../Config'


export function AddCustomerEamil(email, callback) {
  axios({
    method: 'POST',
    url: Config.server + 'api/addCustomer',
    data: { 
        email: email
     },
  }).then(res => {
      if(res.data.status === "success"){
        notify(res.data.status, "Thank you for subscribing", "Subscribed")
      }
      callback(res.data.status)
  }).catch(e => {
       console.log(e)
       callback('failed')
       if(e.response){
           notify("error", e.response.data.message)
       }else{
           notify("error", "Error")
       }
  })
}
  