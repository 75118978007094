import axios from 'axios'
import Config from '../Config'

export function getSocialMedia(callback) {
    axios({
        method: 'GET',
        url: Config.server+'api/getSocialMedia'
    })
    .then(res => {
        callback(res.data.data)
      }).catch(err => {
        console.log(err)
        if(err.response){
            if(err.response.data.message){
                console.log('error', err.response.data.message)
            }
        }else{
            console.log('error', 'Error')
        }
      })
}