import axios from 'axios'
import Config from '../Config'

export function GetAllTeam(callback) {
    axios({
      method: 'GET',
      url: Config.server + 'api/getAllTeam',
    }).then(res => {
      callback(res.data.data)
    }).catch(err => {
      console.log(err)
      if(err.response){
          if(err.response.data.message){
              console.log('error', err.response.data.message)
          }
      }else{
          console.log('error', 'Error')
      }
    })
}

export function GetTeamMateById(id, callback) {
    axios({
      method: 'GET',
      url: Config.server + 'api/getTeamMate/'+id,
    }).then(res => {
      callback(res.data.data)
    }).catch(err => {
      console.log(err)
      if(err.response){
          if(err.response.data.message){
              console.log('error', err.response.data.message)
          }
      }else{
          console.log('error', 'Error')
      }
    })
}

export function getTeamValid() {
    axios({
      method: "GET",
      url: Config.server+'api/getTeamValid'
    })
    .then(res => {
        window.sessionStorage.setItem('JwareAutomationTeamsSection', res.data.valid)
    })
    .catch(err => {
      console.log(err)
      if(err.response){
          if(err.response.data.message){
              console.log('error', err.response.data.message)
          }
      }else{
          console.log('error', 'Error')
      }
    })
}