/* eslint-disable */
import React, { useEffect, lazy, Suspense } from 'react';
import {createBrowserHistory} from 'history';
import {Router, Route, Switch} from 'react-router-dom';
import NotificationContainer from 'react-notifications/lib/NotificationContainer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-notifications/lib/notifications.css';
import 'react-notifications/lib/notifications.css';

// Layout
import Header3 from './components/layout/Header3';
import { Footer } from './components/layout/Footer';
import { useLocation } from 'react-use';
import { getTeamValid } from './api/FetchTeam';

// other
const Cookies = lazy(() => import('./components/other/Cookies'));

// pages
const Home = lazy(() => import('./components/Home'));
const Aboutus_02 = lazy(() => import('./components/pages/Aboutus_02'));
const Services_02 = lazy(() => import('./components/pages/Services_02'));
const Our_expert = lazy(() => import('./components/pages/Our_expert'));
const Team_detail = lazy(() => import('./components/pages/Team_detail'));
const Contact_01 = lazy(() => import('./components/pages/Contact_01'));
const Contact_02 = lazy(() => import('./components/pages/Contact_02'));
const NotFound = lazy(() => import('./components/pages/NotFound'));
const It_consultancy = lazy(() => import('./components/services/It_consultancy'));
const ECommerceSolutions = lazy(() => import('./components/services/ECommerceSolutions'));
const AI_solutions = lazy(() => import('./components/services/AI_solutions'));
const ELearningSolutions = lazy(() => import('./components/services/ELearningSolutions'));
const Web_deployment = lazy(() => import('./components/services/Web_deployment'));
const Mobile_applications = lazy(() => import('./components/services/Mobile_applications'));
const Office_automation = lazy(() => import('./components/services/Office_automation'));
const Industrial_automation = lazy(() => import('./components/services/Industrial_automation'));
const Project_style1 = lazy(() => import('./components/project/Project_style1'));
const Single_style1 = lazy(() => import('./components/project/Single_style1'));
const Blog = lazy(() => import('./components/blog/Blog'));
const Single_blog = lazy(() => import('./components/blog/Single_blog'));
const ScrollToTop = lazy(() => import('./components/layout/Gotop'));
const PrivacyPolicy = lazy(() => import('./components/pages/PrivacyPolicy'));
const Pricing = lazy(() => import('./components/pages/Pricing'));
const Seo = lazy(() => import('./components/services/Seo'));

const appHistory = {
  basename: process.env.PUBLIC_URL,
};
const history = createBrowserHistory(appHistory);

function App() {

    const location = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [location.pathname])

    useEffect(() => {
      // eslint-disable-next-line
      var Tawk_API=Tawk_API || {}, Tawk_LoadStart=new Date();
      (function(){
      var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
      s1.async=true;
      s1.src='https://embed.tawk.to/609e4d69185beb22b30d2e9a/1f5l5spe8';
      s1.charset='UTF-8';
      s1.setAttribute('crossorigin','*');
      s0.parentNode.insertBefore(s1,s0);
      })();

      getTeamValid()
    }, [])


    let teamValid = window.sessionStorage.getItem('JwareAutomationTeamsSection')

    const PreLoader = () => {
      return (
        <div id="preloader">
          <div id="status">&nbsp;</div>
        </div> 
      )
    }

  return (
    <>
      <Router basename={process.env.PUBLIC_URL} history={history}>  
        <Header3/>
        <div className="page">  
          <Suspense fallback={<PreLoader/>} >
            <Cookies />
            <ScrollToTop />
              <Switch>
                  <Route path='/' exact component={Home} /> 
                  <Route path="/aboutus" component={Aboutus_02} />
                  <Route path="/services" component={Services_02} />
                  <Route path="/contact" component={Contact_01} />
                  <Route path="/getaquote" component={Contact_02} />
                  <Route path="/it_consultancy" component={It_consultancy} />
                  <Route path="/ai_solutions" component={AI_solutions} />
                  <Route path="/e-commerce_solutions" component={ECommerceSolutions} />
                  <Route path="/e-learning_solutions" component={ELearningSolutions} />
                  <Route path="/web_deployment" component={Web_deployment} />
                  <Route path="/mobile_applications" component={Mobile_applications} />
                  <Route path="/office_automation" component={Office_automation} />
                  <Route path="/industrial_automation" component={Industrial_automation} />
                  <Route path="/portfolio" component={Project_style1} />
                  <Route path="/project" component={Single_style1} />
                  <Route path="/blogs" component={Blog} />
                  <Route path="/blog" component={Single_blog} />
                  <Route path="/privacy_policy" component={PrivacyPolicy} />
                  <Route path="/pricing" component={Pricing} />
                  <Route path="/seo" component={Seo} />
                  {
                    teamValid === 'true' || teamValid === true ? 
                      <Route path="/our_expert" component={Our_expert} />
                      : ''
                  }
                  {
                    teamValid === 'true' || teamValid === true ? 
                      <Route path="/team_detail" component={Team_detail} />
                      : ''
                  }
                  <Route component={NotFound} />
              </Switch>
            <Footer />
          </Suspense>
        </div> 
    </Router>
    <NotificationContainer/>
    </>
  );
 }

export default App;
