require('dotenv').config()

module.exports ={
    phoneNo1: "+1 720 798 1550",
    phoneNo2: "+17207981550",
    server: process.env.REACT_APP_SERVER || "https://back.jwareautomation.com/",
    mail: "contact@jwareautomation.com",
    address: "",
    google_analytics_site: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
    mode: process.env.REACT_APP_MODE
}

//11/1C, D S Senanayaka Mawatha, Badulla, Sri Lanka
