import React, { Component } from 'react';
import { MDBNavbar, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBCollapse, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem,
MDBHamburgerToggler } from 'mdbreact';

class Mobilemenu extends Component {
state = {
  collapse1: false,
}

toggleSingleCollapse = collapseId => {
  this.setState({
    collapse1: !this.state[collapseId]
  });
}

toggleSingleCollapseLink = collapseId => {
  this.setState({
    collapse1: !this.state[collapseId]
  });

  let hamMenu = document.getElementById('hamburger1')
  hamMenu.click()
}


render() {

  return (

        <MDBNavbar>
            <MDBHamburgerToggler id="hamburger1" onClick={()=> this.toggleSingleCollapse('collapse1')} />
              <MDBCollapse isOpen={this.state.collapse1} navbar>
                <MDBNavbarNav left>
                  <MDBNavItem active>
                    <MDBNavLink onClick={()=> this.toggleSingleCollapseLink('collapse1')} to={process.env.PUBLIC_URL + '/'}>
                      Home
                    </MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink onClick={()=> this.toggleSingleCollapseLink('collapse1')} to={process.env.PUBLIC_URL + '/aboutus'}>
                      About
                    </MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBDropdown>
                        <MDBDropdownToggle  nav caret>
                            <span>Services</span>
                        </MDBDropdownToggle>
                        <MDBDropdownMenu>
                            <MDBDropdownItem onClick={()=> this.toggleSingleCollapseLink('collapse1')} href={process.env.PUBLIC_URL + '/it_consultancy'}>IT Consultancy</MDBDropdownItem>
                            <MDBDropdownItem onClick={()=> this.toggleSingleCollapseLink('collapse1')} href={process.env.PUBLIC_URL + '/ai_solutions'}>AI Solutions</MDBDropdownItem>
                            <MDBDropdownItem onClick={()=> this.toggleSingleCollapseLink('collapse1')} href={process.env.PUBLIC_URL + '/e-commerce_solutions'}>E-Commerce Solutions</MDBDropdownItem>
                            <MDBDropdownItem onClick={()=> this.toggleSingleCollapseLink('collapse1')} href={process.env.PUBLIC_URL + '/e-learning_solutions'}>E-Learning Solutions</MDBDropdownItem>
                            <MDBDropdownItem onClick={()=> this.toggleSingleCollapseLink('collapse1')} href={process.env.PUBLIC_URL + '/web_deployment'}>Web Deployment</MDBDropdownItem>
                            <MDBDropdownItem onClick={()=> this.toggleSingleCollapseLink('collapse1')} href={process.env.PUBLIC_URL + '/mobile_applications'}>Mobile Applications</MDBDropdownItem>
                            <MDBDropdownItem onClick={()=> this.toggleSingleCollapseLink('collapse1')} href={process.env.PUBLIC_URL + '/industrial_automation'}>Industrial Automation</MDBDropdownItem>
                            <MDBDropdownItem onClick={()=> this.toggleSingleCollapseLink('collapse1')} href={process.env.PUBLIC_URL + '/office_automation'}>Office Automation</MDBDropdownItem>
                            <MDBDropdownItem onClick={()=> this.toggleSingleCollapseLink('collapse1')} href={process.env.PUBLIC_URL + '/seo'}>SEO</MDBDropdownItem>
                        </MDBDropdownMenu>
                    </MDBDropdown>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink onClick={()=> this.toggleSingleCollapseLink('collapse1')} to={process.env.PUBLIC_URL + '/portfolio'}>
                      Project
                    </MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink onClick={()=> this.toggleSingleCollapseLink('collapse1')} to={process.env.PUBLIC_URL + '/blogs'}>
                      Blog
                    </MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink onClick={()=> this.toggleSingleCollapseLink('collapse1')} to={process.env.PUBLIC_URL + '/contact'}>
                      Contact
                    </MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink onClick={()=> this.toggleSingleCollapseLink('collapse1')} to={process.env.PUBLIC_URL + '/pricing'}>
                      Pricing
                    </MDBNavLink>
                  </MDBNavItem>
                </MDBNavbarNav>
              </MDBCollapse>
          </MDBNavbar>

    );
  }
}

export default Mobilemenu;