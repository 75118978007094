import React from 'react';
import { Link } from 'react-router-dom';

export default function Menu() {
        return (
            <nav id="menu" role="navigation" className="menu">
                <ul>
                    <li className="menu-hasdropdown">
                        <Link to="/services">services</Link>
                        <ul className="menu-dropdown">
                            <li><Link to="/it_consultancy">IT Consultancy</Link></li>
                            <li><Link to="/ai_solutions">AI Solutions</Link></li>
                            <li><Link to="/e-commerce_solutions">E-Commerce Solutions</Link></li>
                            <li><Link to="/e-learning_solutions">E-Learning Solutions</Link></li>
                            <li><Link to="/web_deployment">Web Deployment</Link></li>
                            <li><Link to="/mobile_applications">Mobile Applications</Link></li>
                            <li><Link to="/industrial_automation">Industrial Automation</Link></li>
                            <li><Link to="/office_automation">Office Automation</Link></li>
                            <li><Link to="/seo">SEO</Link></li>
                        </ul>
                    </li>
                    <li className="menu-hasdropdown">
                        <Link to="/portfolio">Portfolio</Link>
                    </li>
                    <li className="menu-hasdropdown">
                        <Link to="/blogs">Blogs</Link>
                    </li>
                    <li className="menu-hasdropdown">
                        <Link to="/aboutus">About</Link>
                    </li>
                    <li className="menu-hasdropdown">
                        <Link to="/contact">Contact</Link>
                    </li>
                    <li className="menu-hasdropdown"><Link to="/pricing">Pricing</Link></li>
                </ul>
            </nav>
    
        )
}