import { NotificationManager } from 'react-notifications';

export function notify(type, message, title) {
    switch (type) {
        case 'info':
          NotificationManager.info(message);
          break;
        case 'success':
          NotificationManager.success(message, title);
          break;
        case 'warning':
          NotificationManager.warning(message, title);
          break;
        case 'failed':
          NotificationManager.error(message, title);
          break;
        case 'error':
          NotificationManager.error(message, title);
          break;
        default:
            break;
      }
}